.close {
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    padding: 0;
    background-color: initial;
    border: 0;
    span{
    cursor:pointer;

    }
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:focus,
.close:hover {
    opacity: 0.75;
}

.close.disabled,
.close:disabled {
    pointer-events: none;
}
