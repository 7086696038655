$bs-blue: #3b7ddd;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #e83e8c;
$bs-red: red;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #28a745;
$bs-teal: #20c997;
$bs-cyan: #17a2b8;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-primary: #3b7ddd;
$bs-secondary: #6c757d;
$bs-success: #28a745;
$bs-info: #17a2b8;
$bs-warning: #ffc107;
$bs-danger: #dc3545;
$bs-light: #f8f9fa;
$bs-dark: #212529;
$bs-font-sans-serif: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));

@mixin Dc {
    display: flex;
}

@mixin DJcAc {
    @include Dc;
    justify-content: center;
    align-items: center;
}
@mixin DJsbAc {
    @include Dc;
    justify-content: space-between !important;
    align-items: center;
}
@mixin DJsbAfs{
    @include Dc;
    justify-content: space-between !important;
    align-items: flex-start;
}
@mixin DJfsAc {
    @include Dc;
    justify-content: flex-start;
    align-items: center;
}
@mixin DJfeAfe {
    @include Dc;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin DJfsAfe {
    @include Dc;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin DJfeAc {
    @include Dc;
    justify-content: flex-end;
    align-items: center;
}
@mixin DJcAfs {
    @include Dc;
    justify-content: center;
    align-items: flex-start;
}
@mixin DJcAfe {
    @include Dc;
    justify-content: center;
    align-items: flex-end;
}
@mixin fc {
    flex-direction: column;
}
.Dc {
    @include Dc;
}
.DJcAc {
    @include DJcAc;
}
.DJsbAc {
    @include DJsbAc;
}
.DJsbAfs {
    @include DJsbAfs;
}
.DJfsAfe {
    @include DJfsAfe;
}
.DJfeAfe {
    @include DJfeAfe;
}
.DJfsAc {
    @include DJfsAc;
}
.DJfeAc {
    @include DJfeAc;
}
.DJcAfs {
    @include DJfeAc;
}
.DJcAfe {
    @include DJcAfe;
}
.fc {
    @include fc;
}
.margin {
    margin: 0 auto;
}
.btnCell{
    @include DJsbAc;
    width: 100%;
    padding: 2%;
    .btn{
        width: 49%;
    }
}
.clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.justify-content-evenly {
    justify-content: space-evenly !important;
}
.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}
.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}
.align-content-center {
    align-content: center !important;
}
.align-content-between {
    align-content: space-between !important;
}
.align-content-around {
    align-content: space-around !important;
}
.align-content-stretch {
    align-content: stretch !important;
}
.align-self-auto {
    align-self: auto !important;
}
.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-baseline {
    align-self: baseline !important;
}
.align-self-stretch {
    align-self: stretch !important;
}

//借用下地方
// Fonts
$font-family-sans-serif: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-size-base: 0.875rem !default;
$font-size-lg: 0.925rem !default;
$font-size-sm: 0.75rem !default;
$small-font-size: 80% !default;

// Spacer
$spacer: 1rem !default;

$spacers: (
    0: 0,
    1: (
        $spacer * 0.25,
    ),
    2: (
        $spacer * 0.5,
    ),
    3: $spacer,
    4: (
        $spacer * 1.5,
    ),
    5: (
        $spacer * 3,
    ),
    6: (
        $spacer * 4.5,
    ),
    7: (
        $spacer * 6,
    ),
) !default;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
) !default;

// Grid gutter
$grid-gutter-width: 24px;

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
) !default;

$container-padding-x: 0.75rem !default;

// Options
$enable-validation-icons: false !default;

// Transitions
$transition-base: all 0.35s ease-in-out !default;

// Options
$enable-rfs: false !default;
$enable-negative-margins: true !default;

// Theme colors
$blue: #3b7ddd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
) !default;

// Social colors
$social-colors: (
    "facebook": #3b5998,
    "twitter": #1da1f2,
    "google": #dc4e41,
    "youtube": #f00,
    "vimeo": #1ab7ea,
    "dribbble": #ea4c89,
    "github": #181717,
    "instagram": #e4405f,
    "pinterest": #bd081c,
    "flickr": #0063dc,
    "bitbucket": #0052cc,
) !default;

// Body
$body-bg: #f7f7fc !default;
$body-color: $gray-700 !default;

// Links
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Common
$border-width: 1px !default;

// Fonts
$line-height-base: 1.5 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

// Headings
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$headings-color: $black !default;
$headings-font-weight: 400 !default;

// Displays
$display-font-sizes: (
    1: 6rem,
    2: 5.5rem,
    3: 4.5rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem,
) !default;

// Progress
$progress-height: 1rem !default;
$progress-height-sm: 0.5rem !default;
$progress-height-lg: 1.5rem !default;

// Shadows
$box-shadow-sm: 0 0.05rem 0.2rem rgba($black, 0.05) !default;
$box-shadow: 0 0.1rem 0.2rem rgba($black, 0.05) !default;
$box-shadow-lg: 0 0.2rem 0.2rem rgba($black, 0.05) !default;

// Border radius
$border-radius-sm: 0.1rem !default;
$border-radius: 0.2rem !default;
$border-radius-lg: 0.3rem !default;

// Navbar
$navbar-padding-y: $spacer * 0.875 !default;
$navbar-padding-x: $spacer * 1.375 !default;
$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-border-bottom: 0 !default;
$navbar-box-shadow: 0 0 2rem 0 rgba($dark, 0.1) !default;

// Navbar brand
$navbar-brand-padding-y: $spacer * 0.875 !default;
$navbar-brand-padding-x: 0 !default;
$navbar-brand-color: $gray-100 !default;
$navbar-brand-font-weight: $font-weight-normal !default;
$navbar-brand-font-size: 1.15rem !default;
$navbar-brand-icon-color: $primary !default;

$navbar-bg: $white !default;

// Footer
$footer-bg: $white !default;

// Alerts
$alert-padding-y: 0.95rem !default;
$alert-padding-x: 0.95rem !default;
$alert-border-width: 0 !default;

// List group
$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

// Forms
$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-color: $gray-700 !default;
$input-btn-border-width: $border-width !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-focus-width: 0.2rem !default;
$input-btn-padding-y: 0.25rem !default;
$input-btn-padding-y-sm: 0.15rem !default;
$input-btn-padding-y-lg: 0.35rem !default;
$input-btn-padding-x: 0.7rem !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

// Dropdowns
$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-item-padding-y: 0.35rem !default;
$dropdown-item-padding-x: 1.5rem !default;

// Tables
$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.75rem !default;
$table-cell-padding-y-sm: 0.3rem !default;
$table-cell-padding-x-sm: 0.3rem !default;
$table-striped-bg: $gray-100 !default;
$table-hover-bg: rgba($black, 0.0375) !default;
// Cards
$card-border-radius: 0.25rem !default;
$card-inner-border-radius: $card-border-radius !default;
$card-border-width: 0 !default;
$card-border-color: transparent !default;
$card-cap-bg: $white !default;
$card-shadow: 0 0 0.875rem 0 rgba($dark, 0.05) !default;
$card-title-font-weight: $font-weight-normal !default;
$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.25rem !default;
$card-cap-padding-y: 1rem !default;
$card-cap-padding-x: $card-spacer-x !default;
// Sidebar general
$sidebar-width: 260px !default;
$sidebar-transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out !default;
$sidebar-bg: #222e3c !default;

// Sidebar brand
$sidebar-brand-padding-y: 1.15rem !default;
$sidebar-brand-padding-x: 1.5rem !default;
$sidebar-brand-font-weight: $font-weight-bold !default;
$sidebar-brand-font-size: 1.15rem !default;
$sidebar-brand-color: $gray-100 !default;

// Sidebar header
$sidebar-header-padding: 1.5rem 1.5rem 0.375rem !default;
$sidebar-header-font-size: $font-size-sm !default;
$sidebar-header-color: $gray-400 !default;

// Sidebar link
$sidebar-link-padding: 0.625rem 1.625rem !default;
$sidebar-link-font-weight: 400 !default;
$sidebar-link-bg: #222e3c !default;
$sidebar-link-color: rgba($gray-200, 0.5) !default;
$sidebar-link-border-left-color: transparent !default;

$sidebar-link-hover-font-weight: $font-weight-normal !default;
$sidebar-link-hover-bg: #222e3c !default;
$sidebar-link-hover-color: #{rgba($gray-200, 0.75)} !default;
$sidebar-link-hover-border-left-color: transparent !default;

$sidebar-link-active-font-weight: $font-weight-normal !default;
$sidebar-link-active-bg: #{linear-gradient(
        90deg,
        rgba($primary, 0.1) 0%,
        rgba($primary, 0.0875) 50%,
        rgba($black, 0) 100%
    )} !default;
$sidebar-link-active-color: $gray-200 !default;
$sidebar-link-active-border-left-color: $primary !default;

// Sidebar child link
$sidebar-link-child-padding: 0.625rem 1.5rem 0.625rem 3.25rem !default;
$sidebar-link-child-font-size: 90% !default;
$sidebar-link-child-font-weight: 400 !default;

$sidebar-link-child-color: $gray-500 !default;
$sidebar-link-child-bg: transparent !default;
$sidebar-link-child-hover-color: $gray-200 !default;
$sidebar-link-child-hover-bg: transparent !default;
$sidebar-link-child-active-color: #{lighten($primary, 5)} !default;
$sidebar-link-child-active-bg: transparent !default;

// Sidebar link icon
$sidebar-link-icon-color: #{rgba($gray-200, 0.5)} !default;
$sidebar-link-icon-hover-color: #{rgba($gray-200, 0.75)} !default;
$sidebar-link-icon-active-color: $gray-200 !default;

// Sidebar bottom
$sidebar-cta-color: $gray-200 !default;
$sidebar-cta-bg: #2b3947 !default;
$sidebar-cta-padding: $spacer * 1.5 !default;
$sidebar-cta-margin: $spacer * 1.75 !default;
$sidebar-cta-border-radius: $border-radius-lg !default;

// Transitions
$transition-appearance: background 0.4s ease-in-out, color 0.4s ease-in-out !default;
$transition-appearance-slow: background 0.6s ease-in-out, color 0.6s ease-in-out !default;
$transition-appearance-fast: background 0.1s ease-in-out, color 0.1s ease-in-out !default;

// Modals
$modal-content-border-width: 0 !default;
$modal-header-border-width: 1px !default;
$modal-footer-border-width: 1px !default;
$modal-xl: 1140px !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 400px !default;
$modal-transition: transform 0.25s ease-out !default;

// Tabs
$tab-content-padding: $spacer * 1.25 !default;
$tab-content-box-shadow: 0 0.2rem 0.1rem -0.1rem rgba(0, 0, 0, 0.15) !default;
$tab-content-border-radius: 0 0 $border-radius $border-radius !default;
$tab-link-color: $gray-800 !default;
$tab-link-padding: ($spacer * 0.75) $spacer !default;
$tab-link-bg: transparent !default;
$tab-link-active-color: $gray-800 !default;
$tab-link-active-bg: $white !default;

// Badges
$badge-font-size: 80% !default;
$badge-padding-y: 0.3em !default;
$badge-padding-x: 0.45em !default;

// Pagination
$pagination-padding-y: 0.3rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.15rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.35rem !default;
$pagination-padding-x-lg: 1rem !default;
$pagination-color: $gray-600 !default;
$pagination-hover-color: $gray-800 !default;

// Main
$main-box-shadow: inset 0.75rem 0px 1.5rem 0px rgba($black, 0.075);

// Content
$content-padding-desktop: 2.5rem 2.5rem 1rem !default;
$content-padding-mobile: 1.5rem 1.5rem 0.75rem !default;

// Datetimepicker
$bs-datetimepicker-active-bg: $primary !default;

// Simplebar
$simplebar-scrollbar-bg: $white !default;

// Hamburger
$hamburger-width-top: 24px !default;
$hamburger-width-middle: 24px !default;
$hamburger-width-bottom: 16px !default;

// Font Awesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

*,
:after,
:before {
    box-sizing: border-box;
}
.isCan {
    border-radius: 10px;
    z-index: 999;
    position: fixed;
    left: 0;
    top: -99999px;
    margin: auto;
    background-size: 100%;
  }
.treeStyle {
    @include DJcAc;
    @include fc;
    height: 100%;
    #sidebar {
        max-width: 100%;
        min-width: 100%;
        max-height: 100%;
        margin: 0 !important
    }
}
body {
    margin: 0;
    font-family: $bs-font-sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #f7f7fc;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}
.loading-indicator:before {
    content: "";
    background: #000000cc;
    opacity: 0.5;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

.loading-indicator:after {
    content: "Loading...";
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 10000;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}
#root,
body,
html {
    height: 100%;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    html {
        overflow-x: hidden;
    }
}
hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}
hr:not([size]) {
    height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
}
.h1,
h1 {
    font-size: 1.75rem;
}
.h2,
h2 {
    font-size: 1.53125rem;
}
.h3,
h3 {
    font-size: 1.3125rem;
}
.h4,
h4 {
    font-size: 1.09375rem;
}
.h5,
.h6,
h5,
h6 {
    font-size: 0.875rem;
}
p {
    margin: 0;
    // margin-bottom: 1rem;
}
ol,
ul {
    list-style: none;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}
ol,
ul {
    padding-left: 2rem;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 600;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

b,
strong {
    font-weight: bolder;
}
.small,
small {
    font-size: 80%;
}
.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}
sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: initial;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: #3b7ddd;
    text-decoration: none;
}
a:hover {
    color: #1e58ad;
    text-decoration: underline;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
code,
kbd,
pre,
samp {
    font-family: $bs-font-monospace;
    font-size: 1em;
}
pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 80%;
    -ms-overflow-style: scrollbar;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
code {
    font-size: 80%;
    color: #e83e8c;
    word-wrap: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.2rem 0.4rem;
    font-size: 80%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.1rem;
}
kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 600;
}
figure {
    margin: 0 0 1rem;
}
img,
svg {
    vertical-align: middle;
}
table {
    caption-side: bottom;
    border-collapse: collapse;
}
caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
}
th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
    border: 0 solid;
    border-color: inherit;
}
label {
    display: inline-block;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[role="button"] {
    cursor: pointer;
}
select {
    word-wrap: normal;
}
[list]::-webkit-calendar-picker-indicator {
    display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
textarea {
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: normal;
}
legend + * {
    clear: left;
}
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    height: auto;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
    padding: 0;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
iframe {
    border: 0;
}
summary {
    display: list-item;
    cursor: pointer;
}
progress {
    vertical-align: initial;
}
[hidden] {
    display: none !important;
}
.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.initialism {
    font-size: 80%;
    text-transform: uppercase;
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #f7f7fc;
    border: 1px solid #dee2e6;
    border-radius: 0.2rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
    display: block;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.75rem;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.2rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
    display: block;
}

.fade {
    transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}
.fade:not(.show) {
    opacity: 0;
}
.collapse:not(.show) {
    display: none;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}
.clearfix:after {
    display: block;
    clear: both;
    content: "";
}
.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.navbar-nav .dropdown-menu {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}
.dt-bootstrap4 {
    width: calc(100% - 2px);
}
b,
strong {
    font-weight: 600;
}
pre.snippet {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
}
a {
    cursor: pointer;
}
svg {
    touch-action: none;
}
image,
text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.caption-top {
    caption-side: top;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}
[data-toggle="collapse"] {
    position: relative;
}
[data-toggle="collapseLeft"]:after {
    content: " ";
    border: solid;
    border-width: 0 0.075rem 0.075rem 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    position: absolute;
    top: 1.2rem;
    right: 0rem;
    transition: all 0.2s ease-out;
}
[data-toggle="collapse"]:after {
    content: " ";
    border: solid;
    border-width: 0 0.075rem 0.075rem 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    transition: all 0.2s ease-out;
}
[aria-expanded="true"]:after {
    transform: rotate(-135deg) !important;
    top: 1.2rem;
}

@import "@/css/avatar.scss";
@import "@/css/close.scss";
@import "@/css/list.scss";
@import "@/css/btn.scss";
@import "@/css/input.scss";
@import "@/css/display.scss";
@import "@/css/row.scss";
@import "@/css/d_.scss";
@import "@/css/border.scss";
@import "@/css/fixed.scss";
@import "@/css/flex.scss";
@import "@/css/height.scss";
@import "@/css/margin.scss";
@import "@/css/padding.scss";
@import "@/css/card.scss";
@import "@/css/text.scss";
@import "@/css/overflow.scss";
@import "@/css/float.scss";
@import "@/css/container.scss";
@import "@/css/sticky.scss";
@import "@/css/content.scss";
@import "@/css/position.scss";
@import "@/css/tables.scss";
@import "@/css/rounded.scss";
@import "@/css/media.scss";
@import "@/css/width.scss";
