.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: initial;
    border: 1px solid transparent;
    padding: 0.25rem 0.7rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #495057;
    text-decoration: none;
}
.btn-check:focus + .btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
}
.btn-primary {
    color: #fff;
    background-color: #3b7ddd;
    border-color: #3b7ddd;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
    color: #fff;
    background-color: #2469ce;
    border-color: #2264c3;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 145, 226, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2264c3;
    border-color: #205eb8;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 145, 226, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #3b7ddd;
    border-color: #3b7ddd;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-white {
    color: black;
    background-color: #ffffff;
    border-color: #ffffff;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
.btn-outline-primary {
    color: #3b7ddd;
    border-color: #3b7ddd;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #3b7ddd;
    border-color: #3b7ddd;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #3b7ddd;
    border-color: #3b7ddd;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #3b7ddd;
    background-color: initial;
}
.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: initial;
}
.btn-outline-success {
    color: #28a745;
    border-color: #28a745;
}
.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: initial;
}
.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}
.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: initial;
}
.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}
.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: initial;
}
.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: initial;
}
.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: initial;
}
.btn-outline-dark {
    color: #212529;
    border-color: #212529;
}
.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #212529;
    background-color: initial;
}
.btn-link {
    font-weight: 400;
    color: #3b7ddd;
    text-decoration: none;
}
.btn-link:hover {
    color: #1e58ad;
}
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
}
.btn-group-lg > .btn,
.btn-lg {
    padding: 0.35rem 1rem;
    font-size: 0.925rem;
    border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
    padding: 0.15rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.1rem;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
    position: relative;
    flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
    z-index: 1;
}
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
    margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
    width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-pill {
    border-radius: 10rem;
}
.btn-square {
    border-radius: 0;
}
.btn .feather {
    width: 14px;
    height: 14px;
}
.btn-danger,
.btn-danger.disabled,
.btn-danger.focus,
.btn-danger.hover:not(:disabled):not(.disabled),
.btn-danger:disabled,
.btn-danger:focus,
.btn-danger:hover:not(:disabled):not(.disabled),
.btn-dark,
.btn-dark.disabled,
.btn-dark.focus,
.btn-dark.hover:not(:disabled):not(.disabled),
.btn-dark:disabled,
.btn-dark:focus,
.btn-dark:hover:not(:disabled):not(.disabled),
.btn-info,
.btn-info.disabled,
.btn-info.focus,
.btn-info.hover:not(:disabled):not(.disabled),
.btn-info:disabled,
.btn-info:focus,
.btn-info:hover:not(:disabled):not(.disabled),
.btn-light,
.btn-light.disabled,
.btn-light.focus,
.btn-light.hover:not(:disabled):not(.disabled),
.btn-light:disabled,
.btn-light:focus,
.btn-light:hover:not(:disabled):not(.disabled),
.btn-outline-danger.hover:not(:disabled):not(.disabled),
.btn-outline-danger:hover:not(:disabled):not(.disabled),
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-dark.hover:not(:disabled):not(.disabled),
.btn-outline-dark:hover:not(:disabled):not(.disabled),
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-info.hover:not(:disabled):not(.disabled),
.btn-outline-info:hover:not(:disabled):not(.disabled),
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-light.hover:not(:disabled):not(.disabled),
.btn-outline-light:hover:not(:disabled):not(.disabled),
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-primary.hover:not(:disabled):not(.disabled),
.btn-outline-primary:hover:not(:disabled):not(.disabled),
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-secondary.hover:not(:disabled):not(.disabled),
.btn-outline-secondary:hover:not(:disabled):not(.disabled),
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-success.hover:not(:disabled):not(.disabled),
.btn-outline-success:hover:not(:disabled):not(.disabled),
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-warning.hover:not(:disabled):not(.disabled),
.btn-outline-warning:hover:not(:disabled):not(.disabled),
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-primary,
.btn-primary.disabled,
.btn-primary.focus,
.btn-primary.hover:not(:disabled):not(.disabled),
.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover:not(:disabled):not(.disabled),
.btn-secondary,
.btn-secondary.disabled,
.btn-secondary.focus,
.btn-secondary.hover:not(:disabled):not(.disabled),
.btn-secondary:disabled,
.btn-secondary:focus,
.btn-secondary:hover:not(:disabled):not(.disabled),
.btn-success,
.btn-success.disabled,
.btn-success.focus,
.btn-success.hover:not(:disabled):not(.disabled),
.btn-success:disabled,
.btn-success:focus,
.btn-success:hover:not(:disabled):not(.disabled),
.btn-warning,
.btn-warning.disabled,
.btn-warning.focus,
.btn-warning.hover:not(:disabled):not(.disabled),
.btn-warning:disabled,
.btn-warning:focus,
.btn-warning:hover:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle,
.show > .btn-dark.dropdown-toggle,
.show > .btn-info.dropdown-toggle,
.show > .btn-light.dropdown-toggle,
.show > .btn-primary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-success.dropdown-toggle,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
}
.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}
.btn-check:focus + .btn-facebook,
.btn-facebook:focus,
.btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
}
.btn-check:focus + .btn-facebook,
.btn-facebook:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-check:active + .btn-facebook,
.btn-check:checked + .btn-facebook,
.btn-facebook.active,
.btn-facebook:active,
.show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a;
}
.btn-check:active + .btn-facebook:focus,
.btn-check:checked + .btn-facebook:focus,
.btn-facebook.active:focus,
.btn-facebook:active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}
.btn-facebook,
.btn-facebook.disabled,
.btn-facebook.focus,
.btn-facebook.hover:not(:disabled):not(.disabled),
.btn-facebook:disabled,
.btn-facebook:focus,
.btn-facebook:hover:not(:disabled):not(.disabled),
.show > .btn-facebook.dropdown-toggle {
    color: #fff;
}
.btn-twitter {
    color: #212529;
    background-color: #1da1f2;
    border-color: #1da1f2;
}
.btn-check:focus + .btn-twitter,
.btn-twitter:focus,
.btn-twitter:hover {
    color: #fff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
}
.btn-check:focus + .btn-twitter,
.btn-twitter:focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 142, 212, 0.5);
}
.btn-check:active + .btn-twitter,
.btn-check:checked + .btn-twitter,
.btn-twitter.active,
.btn-twitter:active,
.show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4;
}
.btn-check:active + .btn-twitter:focus,
.btn-check:checked + .btn-twitter:focus,
.btn-twitter.active:focus,
.btn-twitter:active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 142, 212, 0.5);
}
.btn-twitter.disabled,
.btn-twitter:disabled {
    color: #212529;
    background-color: #1da1f2;
    border-color: #1da1f2;
}
.btn-twitter,
.btn-twitter.disabled,
.btn-twitter.focus,
.btn-twitter.hover:not(:disabled):not(.disabled),
.btn-twitter:disabled,
.btn-twitter:focus,
.btn-twitter:hover:not(:disabled):not(.disabled),
.show > .btn-twitter.dropdown-toggle {
    color: #fff;
}
.btn-google {
    color: #fff;
    background-color: #dc4e41;
    border-color: #dc4e41;
}
.btn-check:focus + .btn-google,
.btn-google:focus,
.btn-google:hover {
    color: #fff;
    background-color: #d03526;
    border-color: #c63224;
}
.btn-check:focus + .btn-google,
.btn-google:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.btn-check:active + .btn-google,
.btn-check:checked + .btn-google,
.btn-google.active,
.btn-google:active,
.show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #c63224;
    border-color: #bb2f22;
}
.btn-check:active + .btn-google:focus,
.btn-check:checked + .btn-google:focus,
.btn-google.active:focus,
.btn-google:active:focus,
.show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.btn-google.disabled,
.btn-google:disabled {
    color: #fff;
    background-color: #dc4e41;
    border-color: #dc4e41;
}
.btn-google,
.btn-google.disabled,
.btn-google.focus,
.btn-google.hover:not(:disabled):not(.disabled),
.btn-google:disabled,
.btn-google:focus,
.btn-google:hover:not(:disabled):not(.disabled),
.show > .btn-google.dropdown-toggle {
    color: #fff;
}
.btn-youtube {
    color: #fff;
    background-color: red;
    border-color: red;
}
.btn-check:focus + .btn-youtube,
.btn-youtube:focus,
.btn-youtube:hover {
    color: #fff;
    background-color: #d90000;
    border-color: #c00;
}
.btn-check:focus + .btn-youtube,
.btn-youtube:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-check:active + .btn-youtube,
.btn-check:checked + .btn-youtube,
.btn-youtube.active,
.btn-youtube:active,
.show > .btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: #c00;
    border-color: #bf0000;
}
.btn-check:active + .btn-youtube:focus,
.btn-check:checked + .btn-youtube:focus,
.btn-youtube.active:focus,
.btn-youtube:active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-youtube.disabled,
.btn-youtube:disabled {
    color: #fff;
    background-color: red;
    border-color: red;
}
.btn-youtube,
.btn-youtube.disabled,
.btn-youtube.focus,
.btn-youtube.hover:not(:disabled):not(.disabled),
.btn-youtube:disabled,
.btn-youtube:focus,
.btn-youtube:hover:not(:disabled):not(.disabled),
.show > .btn-youtube.dropdown-toggle {
    color: #fff;
}
.btn-vimeo {
    color: #212529;
    background-color: #1ab7ea;
    border-color: #1ab7ea;
}
.btn-check:focus + .btn-vimeo,
.btn-vimeo:focus,
.btn-vimeo:hover {
    color: #fff;
    background-color: #139ecb;
    border-color: #1295bf;
}
.btn-check:focus + .btn-vimeo,
.btn-vimeo:focus {
    box-shadow: 0 0 0 0.2rem rgba(27, 161, 205, 0.5);
}
.btn-check:active + .btn-vimeo,
.btn-check:checked + .btn-vimeo,
.btn-vimeo.active,
.btn-vimeo:active,
.show > .btn-vimeo.dropdown-toggle {
    color: #fff;
    background-color: #1295bf;
    border-color: #108cb4;
}
.btn-check:active + .btn-vimeo:focus,
.btn-check:checked + .btn-vimeo:focus,
.btn-vimeo.active:focus,
.btn-vimeo:active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(27, 161, 205, 0.5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled {
    color: #212529;
    background-color: #1ab7ea;
    border-color: #1ab7ea;
}
.btn-vimeo,
.btn-vimeo.disabled,
.btn-vimeo.focus,
.btn-vimeo.hover:not(:disabled):not(.disabled),
.btn-vimeo:disabled,
.btn-vimeo:focus,
.btn-vimeo:hover:not(:disabled):not(.disabled),
.show > .btn-vimeo.dropdown-toggle {
    color: #fff;
}
.btn-dribbble {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}
.btn-check:focus + .btn-dribbble,
.btn-dribbble:focus,
.btn-dribbble:hover {
    color: #fff;
    background-color: #e62a72;
    border-color: #e51e6b;
}
.btn-check:focus + .btn-dribbble,
.btn-dribbble:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.btn-check:active + .btn-dribbble,
.btn-check:checked + .btn-dribbble,
.btn-dribbble.active,
.btn-dribbble:active,
.show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #e51e6b;
    border-color: #dc1a65;
}
.btn-check:active + .btn-dribbble:focus,
.btn-check:checked + .btn-dribbble:focus,
.btn-dribbble.active:focus,
.btn-dribbble:active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}
.btn-dribbble,
.btn-dribbble.disabled,
.btn-dribbble.focus,
.btn-dribbble.hover:not(:disabled):not(.disabled),
.btn-dribbble:disabled,
.btn-dribbble:focus,
.btn-dribbble:hover:not(:disabled):not(.disabled),
.show > .btn-dribbble.dropdown-toggle {
    color: #fff;
}
.btn-github {
    color: #fff;
    background-color: #181717;
    border-color: #181717;
}
.btn-check:focus + .btn-github,
.btn-github:focus,
.btn-github:hover {
    color: #fff;
    background-color: #040404;
    border-color: #000;
}
.btn-check:focus + .btn-github,
.btn-github:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.btn-check:active + .btn-github,
.btn-check:checked + .btn-github,
.btn-github.active,
.btn-github:active,
.show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.btn-check:active + .btn-github:focus,
.btn-check:checked + .btn-github:focus,
.btn-github.active:focus,
.btn-github:active:focus,
.show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.btn-github.disabled,
.btn-github:disabled {
    color: #fff;
    background-color: #181717;
    border-color: #181717;
}
.btn-github,
.btn-github.disabled,
.btn-github.focus,
.btn-github.hover:not(:disabled):not(.disabled),
.btn-github:disabled,
.btn-github:focus,
.btn-github:hover:not(:disabled):not(.disabled),
.show > .btn-github.dropdown-toggle {
    color: #fff;
}
.btn-instagram {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}
.btn-check:focus + .btn-instagram,
.btn-instagram:focus,
.btn-instagram:hover {
    color: #fff;
    background-color: #de1f44;
    border-color: #d31e40;
}
.btn-check:focus + .btn-instagram,
.btn-instagram:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.btn-check:active + .btn-instagram,
.btn-check:checked + .btn-instagram,
.btn-instagram.active,
.btn-instagram:active,
.show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #d31e40;
    border-color: #c81c3d;
}
.btn-check:active + .btn-instagram:focus,
.btn-check:checked + .btn-instagram:focus,
.btn-instagram.active:focus,
.btn-instagram:active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.btn-instagram.disabled,
.btn-instagram:disabled {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}
.btn-instagram,
.btn-instagram.disabled,
.btn-instagram.focus,
.btn-instagram.hover:not(:disabled):not(.disabled),
.btn-instagram:disabled,
.btn-instagram:focus,
.btn-instagram:hover:not(:disabled):not(.disabled),
.show > .btn-instagram.dropdown-toggle {
    color: #fff;
}
.btn-pinterest {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}
.btn-check:focus + .btn-pinterest,
.btn-pinterest:focus,
.btn-pinterest:hover {
    color: #fff;
    background-color: #980617;
    border-color: #8c0615;
}
.btn-check:focus + .btn-pinterest,
.btn-pinterest:focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.btn-check:active + .btn-pinterest,
.btn-check:checked + .btn-pinterest,
.btn-pinterest.active,
.btn-pinterest:active,
.show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #8c0615;
    border-color: #800513;
}
.btn-check:active + .btn-pinterest:focus,
.btn-check:checked + .btn-pinterest:focus,
.btn-pinterest.active:focus,
.btn-pinterest:active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}
.btn-pinterest,
.btn-pinterest.disabled,
.btn-pinterest.focus,
.btn-pinterest.hover:not(:disabled):not(.disabled),
.btn-pinterest:disabled,
.btn-pinterest:focus,
.btn-pinterest:hover:not(:disabled):not(.disabled),
.show > .btn-pinterest.dropdown-toggle {
    color: #fff;
}
.btn-flickr {
    color: #fff;
    background-color: #0063dc;
    border-color: #0063dc;
}
.btn-check:focus + .btn-flickr,
.btn-flickr:focus,
.btn-flickr:hover {
    color: #fff;
    background-color: #0052b6;
    border-color: #004ca9;
}
.btn-check:focus + .btn-flickr,
.btn-flickr:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.btn-check:active + .btn-flickr,
.btn-check:checked + .btn-flickr,
.btn-flickr.active,
.btn-flickr:active,
.show > .btn-flickr.dropdown-toggle {
    color: #fff;
    background-color: #004ca9;
    border-color: #00469c;
}
.btn-check:active + .btn-flickr:focus,
.btn-check:checked + .btn-flickr:focus,
.btn-flickr.active:focus,
.btn-flickr:active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.btn-flickr.disabled,
.btn-flickr:disabled {
    color: #fff;
    background-color: #0063dc;
    border-color: #0063dc;
}
.btn-flickr,
.btn-flickr.disabled,
.btn-flickr.focus,
.btn-flickr.hover:not(:disabled):not(.disabled),
.btn-flickr:disabled,
.btn-flickr:focus,
.btn-flickr:hover:not(:disabled):not(.disabled),
.show > .btn-flickr.dropdown-toggle {
    color: #fff;
}
.btn-bitbucket {
    color: #fff;
    background-color: #0052cc;
    border-color: #0052cc;
}
.btn-bitbucket:focus,
.btn-bitbucket:hover,
.btn-check:focus + .btn-bitbucket {
    color: #fff;
    background-color: #0043a6;
    border-color: #003e99;
}
.btn-bitbucket:focus,
.btn-check:focus + .btn-bitbucket {
    box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.btn-bitbucket.active,
.btn-bitbucket:active,
.btn-check:active + .btn-bitbucket,
.btn-check:checked + .btn-bitbucket,
.show > .btn-bitbucket.dropdown-toggle {
    color: #fff;
    background-color: #003e99;
    border-color: #00388c;
}
.btn-bitbucket.active:focus,
.btn-bitbucket:active:focus,
.btn-check:active + .btn-bitbucket:focus,
.btn-check:checked + .btn-bitbucket:focus,
.show > .btn-bitbucket.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.btn-bitbucket.disabled,
.btn-bitbucket:disabled {
    color: #fff;
    background-color: #0052cc;
    border-color: #0052cc;
}
.btn-bitbucket,
.btn-bitbucket.disabled,
.btn-bitbucket.focus,
.btn-bitbucket.hover:not(:disabled):not(.disabled),
.btn-bitbucket:disabled,
.btn-bitbucket:focus,
.btn-bitbucket:hover:not(:disabled):not(.disabled),
.show > .btn-bitbucket.dropdown-toggle {
    color: #fff;
}
.btn-light,
.btn-light.disabled,
.btn-light.focus,
.btn-light.hover:not(:disabled):not(.disabled),
.btn-light:disabled,
.btn-light:focus,
.btn-light:hover:not(:disabled):not(.disabled),
.btn-outline-light.hover:not(:disabled):not(.disabled),
.btn-outline-light:hover:not(:disabled):not(.disabled),
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-white.hover:not(:disabled):not(.disabled),
.btn-outline-white:hover:not(:disabled):not(.disabled),
.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-white,
.btn-white.disabled,
.btn-white.focus,
.btn-white.hover:not(:disabled):not(.disabled),
.btn-white:disabled,
.btn-white:focus,
.btn-white:hover:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle,
.show > .btn-white.dropdown-toggle {
    color: #343a40;
}